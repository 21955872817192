import React from 'react';
import {  RecoilRoot } from 'recoil'
//import ModalVideo from 'react-modal-video';

const WhitePaper = (props) => {
    const [isOpen, setIsOpen] = React.useState(true);
    const openModal = () => {
        setIsOpen(!isOpen);
    }

    return (
        <React.Fragment>
            <RecoilRoot>
            {/* If you want to change the video need to update videoID */}


            <div id="intro" className="about-area pb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-image-box">
                                <img src={require("about/about-img9.jpg")} alt="image" />

                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={require("star-icon.png")} alt="image" />
                                        White Paper
                                    </span>
                                    <a><h2>NiftyRiots</h2></a>
                                    <p>We are very pleased to introduce NiftyRiots – The first ever large scale NFT highest quality art collection entirely created with Artificial Intelligence. We bring you 11,111 exciting and original works of art. To us they are all masterpieces and we cherish them dearly. Besides being the first collection of its kind, it is also in our view, the only one which gives the community member a chance to really participate and create art of their own. Just like other projects we give the opportunity to name the token to create a special bond with it, but what is truly unique is that we also give you the tools to create more artworks. This is a first in art history. We release the weights to the neural network which has been used to create this art so that you, our dear community member, can create new art. Imagine Rembrandt or Basquiat teaching you how to paint, one on one. We will also take the opportunity to tell you more about “latentism” and art created with A.I. Our purpose is to bring this new exciting approach to art to a wider audience and to help in bringing A.I. and blockchain technologies closer.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="circle-shape1">
                    <img src={require("shape/circle-shape1.png")} alt="image" />
                </div>
            </div>


            <div id="ai" className="explore-learning-area ptb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="explore-learning-content">
                                <h2 >Made with A.I.</h2>
                                <p>
                                Art created with A.I. has been around since about 2017-2018 when GPUs reached the computing capacity sufficient enough to run Generative Adversarial Networks (GANs). GANs are pairs of neural networks (one Generator and one Discriminator) competing against each other and by this process allowing to generate images more and more indistinguishable from the dataset it is trained on. This technology is very resource intensive and training GANs can take several weeks to get good results at a high definition.
                                Gans concentrate the information contained in the dataset in the so called “latent” space. The generative process takes points in the latent space and projects them back into the images space. This allows to get entirely new images. As long as you have the weights of the Generator network, you can create an infinity of new original artworks. This is truly mind blowing. With this process one can generate an infinity of works. This brings our creative capacity truly beyond what is possible to do with just our mind. The A.I. gives us uber-human capacities. Without A.I. it would be totally impossible to create 11,111 works of art without obvious repetitions in them.

                                For example, projects like Hashmasks, which we are really fans of use an alternative compositional approach. They create the image by composing from various pre-existing elements. While this allows to create a large amount of different images, it also leads to repetitive works and monotonous creations. This is just a limitation of their approach.

                                [images]
                                Cool but similar?

                                We want to go beyond that and use A.I. to create the artwork from the ground up. It makes images by exploring the latent space as one of the fundamental steps in the production pipeline. This ensures all works are truly original, not just slightly different from each other.

                                [images] Pretty and unique

                                The whole creation pipeline is complex and aggregates different A.I. techniques including super-resolution and style transfer. Putting this pipeline together has been an exciting project and we get weak in the knees when we see the results.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="explore-learning-video">
                                <img src={props.S3_ADDRESS + "webimages/compare.png"} alt="image" />
                            </div>
                            <div id="own"/>
                        </div>
                    </div>
                </div>
            </div>


            <div id="create" className="explore-learning-area ptb-100">
                <div className="container">
                    <div className="row align-items-center">



                        <div className="col-lg-6 col-md-12">
                            <div className="explore-learning-video">
                                <video className="video-fluid z-depth-1 center-block animate__fadeIn" style={{animationDuration: '0s'}} autoPlay loop controls muted >
                                    <source src={props.S3_ADDRESS + "webimages/output.mp4"}  type="video/mp4" />
                                </video>
                            </div>
                            <div id="own"/>
                        </div>



                        <div className="col-lg-6 col-md-12">
                            <div className="explore-learning-content">
                                <h2 >You Can Create</h2>
                                <p>For the first time, we would like to give you the tools to become a creator. We all have this desire within us to express our creativity with artistic endeavours be it with music, drawing, painting or digital creations. To give you a kick start, we are releasing the weights to the generator of a GAN network trained on the dataset of more than 40,000 of our artworks. Yes, this generator has been trained on about 4 times as much images than there are in the core NiftyRiots NFT released dataset, so that it can create an even richer range of artworks.
What does it mean in simple terms? Now you can use A.I. to generate new exciting artworks which are similar in style to NiftyRiots. You are free to use those artworks for any purpose you like, be it personal or commercial. You can even create a second branch of NiftyRiots and mint a second #nft drop based on it. You have that power in your hands. Just need to be able to run code.
Does it take programming skills to be able to generate artworks? It actually does and you’d better brush up your python and tensorflow skills but we are pretty sure that some community members will be inclined to make it easy for you and will publish apps whereby you can create new art by the click of a button. The A.I. community and the crypto community is made of altruistic people and we cherish that a lot. We welcome community tools and are looking forward to seeing a whole ecosystem flourish around this project. We make it easy for you to get started in making art.
We would like to emphasize that this approach is a first and has never been tried before but we anticipate that many future project will follow this model since it really engages the community into the creative process around the core works of art included in the NFT drop. We are delighted to be the setting new baselines for this emerging tech.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className="explore-learning-area ptb-100">
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-lg-6 col-md-12">
                            <div className="explore-learning-content">
                            <h2 >You Own</h2>
                            <p>
                            As the owner of a NiftyRiots non fungible token (NFT) on the ethereum blockchain, you can enjoy the usage of the image for commercial or private usage. The core 11,111 works of art make the DNA of NiftyRiots and are truly precious in that respect. You can use it for merchandising or to sell printed art works. We personally absolutely love it to have this art in our wallets but also on our walls. We can contemplate it after a hard day of painting (or coding) and find the motivation to push further.

                            Beyond that, all the works created by you while using the Generator network we release are also free for you to use. We love to see NiftyRiots all over the world and really looking forward to seeing what you can come up with. Your contribution will make this project richer.

                            The crypto community shares values of inclusiveness and is altruistic and we want to give back to it by not holding you to stringent reproduction rights. If you decide to get an NFT from the collection it will already makes us very happy.
                            </p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="explore-learning-video">
                                <img src={props.S3_ADDRESS + "webimages/youown.png"} alt="image" />
                            </div>
                            <div id="own"/>
                        </div>


                     <div id="collectible" />
                    </div>
                </div>
            </div>

            <div className="explore-learning-area ptb-100">
                <div className="container">
                    <div className="row align-items-center">


                        <div className="col-lg-6 col-md-12">
                            <div className="explore-learning-video">
                                <img src={props.S3_ADDRESS + "webimages/comparevalue.png"} alt="image" />
                            </div>
                            <div id="own"/>
                        </div>


                        <div className="col-lg-6 col-md-12">
                            <div className="explore-learning-content">
                            <h2>Highly Collectible</h2>
                            <p>
                            Let’s be real. There are hundreds of NFT collections out there which are looking to capitalize on the current community interest for this exciting new medium. But how many of those are really unique and bring something new to the table? Cyber-this, Crypto-that, punk-this, kitties-that. Looking at most projects they all look the same and seem to have been put together in a rush. Nevertheless, among this avalanche of collections a few manage to stand out and are setting new baselines for our community.
                            Hashmasks is one of our favourite projects and we love to think that we have created the next iteration of it by adding the magical A.I. component to the creative process. That being said we also differ in our approach with regards to giving a value to the artworks. Indeed, according to their manifesto Hashmasks have different values because the user can give them names and because the presence of  some elements in the picture (or the absence thereof) makes it more or less collectible. We agree with the first part that naming the work of art can create a special bond to it, and NiftyRiots also allows the owner to give it a name, but we disagree with the second part. Art is not accounting! Because the character in the picture holds two books rather than one, it doesn’t make it more valuable. We do not want our followers and community members to be reduced to having this simplistic view about art. We are not counting objects here. Would you say that  “A country brawl” by Brueghel has more value than “The Winter landscape with a bird trap” because the latter has more trees in the picture than the former? This is nonsense. In our view, the accounting approach to forming value was just used because there was a lack of artistic and aesthetic values in the actual works (since this originated with collecting cards). But welcome to the new world! the A.I. is used to make art, not just combine objects in a clever way, so that true value is now in the aesthetics. Crypto art is growing up, we are glad to encourage this process. We need more maturity and a new aesthetic appreciation of those works. We ain’t counting trees or batons.
                            So how to determine which of the NiftyRiots is more valuable? To us they are all created equal and the value is for you to see. If you like Basquiat then maybe some of the NiftyRiots inspired by Basquiat will feel more desirable to you:
                            [image]
                            On the other hand, if you prefer more abstract works, then other niftyriots like those might feel more precious:

                            We want you to assign value to them based on your art preferences. We have some favourites too..but we won’t tell which ones they are just now:)
                            Let’s have the community mature and learn to appreciate true art, with reference to art of the past, and anticipating art of the future.
                            </p>
                            </div>
                        </div>
                        <div id="art" />



                    </div>
                </div>
            </div>

            <div className="explore-learning-area ptb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="explore-learning-content">
                            <h2 >Art Avant-Garde</h2>
                            <p>The avant-garde (from French, 'advance guard' or 'vanguard', literally 'fore-guard') are people or works that are experimental, radical, or unorthodox with respect to art, culture, or society. It is frequently characterized by aesthetic innovation and initial unacceptability.
A.I. art can be called avant-garde because some actors still do not get it and refuse to believe that machines can create art or that machines cannot help in creating art...because – They say – Machines have no soul. What they say is that they are interested in the artist personality, their obsessions and their passions, and that this is totally opposite to A.I.
What a lack of judgement!

Behind the A.I. there are the human minds which create the computer language code which creates the art. Those humans infuse and tweak the process to create the art they love, to create the art which brings them happiness and which allows them to express their personality. We shall give time to people to reflect on this new complicated topic and come to terms with the fact that A.I. art is as human  (and humanistic) as other forms of art.

Our Artistic Director is a leader in A.I. art since 2017 and we will give more details about this after the release. Our collection has been created with the collaboration of a few experts in the field of A.I. and A.I. art. Who will believe that 70 artists collaborated on this project (wink wink Hashmasks – please release the names of your own 70 artists, We would love to know who they are and buy their work), except if that means cheap labourers from fivver? Let’s get real.

The NFT space is a natural habitat for A.I. art and we are happy to promote this new creative process. All the while one concern we have is that some NFT collections are more collectibles than art. While we love Mad Dog Jones or Peeble, other “art” in the NFT sphere just does not seem up to par. Is there aesthetic value in crypto punks? Are crypto kitties art? We let you decide, but we have a strong opinion about this topic.

We believe you, our dear community members, are smart individuals with time to spare to reflect on those complicated issues. We wish to start and maintain an exchange with you about A.I. art and about the process used to created it. We would also be thrilled to see NFT art mature and gain in aesthetic value and depart from an accounting source of value to a truly perceptual aesthetic or moral source of value.</p>
                            </div>
                        </div>
                        <div id="learn" />

                        <div className="col-lg-6 col-md-12">
                            <div className="explore-learning-video">
                                <img src={props.S3_ADDRESS + "webimages/team.png"} alt="image" />
                            </div>
                            <div id="own"/>
                        </div>


                    </div>
                </div>
            </div>


            </RecoilRoot>
        </React.Fragment>
    )
}

export default WhitePaper;
