import React from 'react';
import NavbarTwoCustom from './_App/NavbarTwoCustom';  //   <NavbarTwoCustom />
import FooterMin from './_App/FooterMin';
import GoTop from './_App/GoTop';  //   <NavbarTwoCustom />
import WhitePaper from './MachineLearningAISolutions/WhitePaper';
import {  RecoilRoot } from 'recoil'


class WhitePaperPage extends React.Component {

  constructor(props) {
    super(props);
  }

    render() {
      return (
          <React.Fragment>
            <RecoilRoot>
              <NavbarTwoCustom/>
              <WhitePaper S3_ADDRESS={this.props.S3_ADDRESS}/>
              <FooterMin />
              <GoTop />
            </RecoilRoot>
          </React.Fragment>
      );
    }
  }

export default WhitePaperPage